import React from 'react'
import './Footer.css'

const Footer = () => {
  const year = new Date().getFullYear()
  return (
    <footer>
      <p className="copyright">
      جميع الحقوق محفوظة لموقع مجمومة المشاريع الذكية © {year}
      </p>
    </footer>
  )
}

export default Footer
