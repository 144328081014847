import React , {useState , useEffect}from 'react'
import './ExperienceCard.css'

const ExperienceCard = ({ experience }) => {
  let { link, company, title, dateFrom, dateTo, info, stack } = experience

  
  const [pupValue , setPupValue] = useState('')

  const handleChange = (e) => {
    setPupValue(e.target.value)
  }

  const [pupModel , setPupModel] = useState(false)
  const handleClick = () => {
    setPupModel(!pupModel)
  }

  
  return (
    // <a
    //   className="experience-link"
    //   href={link}
    //   target="_blank"
    //   rel="noopener noreferrer"
    // >
    <div>
      <div className="experience-card-wrapper">
        <div className="experience-card">
          <div className="experience-card-top">
            <div
              className="experience-bg"
              style={{ background: experience.colourPrimary }}
            ></div>
            <h2>{company}</h2>
            <div className="image-wrapper">
              <div
                className="experience-bgtow logo-bg"
                style={{
                  background: experience.colourSecondary
                    ? experience.colourSecondary
                    : experience.colourPrimary,
                }}
              ></div>
              <img
                className="company-logo"
                src={require(`../../images/logos/${company}.png`)}
                alt={`${company}-logo`}
                style={
                  experience.logoheight
                    ? {
                        height: `${experience.logoheight}%`,
                      }
                    : { width: `${experience.logowidth}%` }
                }
              />
            </div>
          </div>
          <div className="experience-card-bottom">
            <div>
              <h2>{title}</h2>
              <h3>
                {dateFrom} <span className='hhh'> {dateTo} </span>
              </h3>
                {info.map((point, idx) => (
                  <div onClick={handleChange} key={`${company}-point-${idx}`}>
              <button onClick={handleClick} value={point} className='btn-hover butn'>
              تفاصيل المنتج 
              </button>  
                  </div>
                ))}
              {/* <button onClick={handleClick} value={point} className='btn-hover butn'>
              تفاصيل المنتج 
              </button> */}
            </div>
            <div className="experience-card-tech">
              <ul>
                {stack.map((tech) => (
                  <li key={`${company}-${tech}`}>{tech}</li>
                ))}
              </ul>
            </div>
              <>
              <a
              href={`https://api.whatsapp.com/send?phone=966555755188&text= السلام%20عليكم%20.%20امل%20التواصل%20لطلب%20${company}&source=&data=&app_absent=`}
            target="_blank"
            rel="noopener noreferrer"
          >
                        <button  className='btn-hover butn'>
                        طلب المنتج
                        </button>

          </a>
              </>
              
          </div>
        
        </div>
      </div>

      { pupModel && ( 
        <>
       {
          pupValue === 'model2' && (
            <> 
        <div className='pupModelContener'>


        <div
              className="experience-bg"
              style={{ background: experience.colourPrimary }}
            ></div>
            <div className="image-wrapper">
              <div
                className="experience-bgtow logo-bg"
                style={{
                  background: experience.colourSecondary
                    ? experience.colourSecondary
                    : experience.colourPrimary,
                }}
              ></div>
              <img
                className="company-logo"
                src={require(`../../images/logos/${company}.png`)}
                alt={`${company}-logo`}
                style={
                  experience.logoheight
                    ? {
                        height: `${experience.logoheight}%`,
                      }
                    : { width: `${experience.logowidth}%` }
                }
              />
            </div>


            <div className="experience-card-bottom modeltext">

              <button className='btnClose' onClick={handleClick}>✖️</button>
            <h1>
 التفاصيل
 </h1>
 <br/>

              <div>        سعر القبة   14500
مقاس القبة   الارتفاع 3.8    والعرض   * 4.3 4.3   متر
السعر شامل النقل واصل وشامل الهيكل والغطاء .
السعر غير شامل الضريبة .</div>
<br/>
              <div> 
سعر القبة  17500
مقاس القبة  الارتفاع   4.8   والعرض   8 * 8   متر
السعر شامل النقل واصل وشامل الهيكل والغطاء .
السعر غير شامل الضريبة .</div>
              <br/>
              <h1>المواصفات الفنية لهيكل القبب المعدنية بالأحجام المختلفة</h1>
              <br/>


 
 <div>
 ابعاد البيت : قطر 4.3 م ،ارتفاع 3.8 م .
المساحة الاجمالية : 15 م 2
الغطاء : الجلد الشفاف والملون
 </div>
 <br/>

 
 <div>
 ابعاد البيت : قطر 8 م ،ارتفاع 4.8 م .
المساحة الاجمالية : 50 م 2
الغطاء : الجلد الشفاف والملون
 </div>
 <br/>

  
 <h1>
 التصميم
 </h1>
 <br/>

 

 <div>
القبب مصممة على عدة أسس منها:
- تحمل سرعة رياح لغاية 110 كم / الساعة.
- ملائمة لعدة أنواع من الأغطية الزراعية.
- جميع الدعامات من الحديد المقوى مجلفن لتوفير مظهر انيق مقاوم للصدأ .
يزود القبة بأبواب بالمواصفات التالية:
- البيت مزود بعدد 1 باب
- الأبواب مصنوعة من أنابيب مجلفنة قياس 32 × 1.5 ملم .
غطاء القبة :
يغطى القبة السياحية الزراعي كامل بالجلد بالمواصفات التالية :
سماكة 600-500 ميكرون. 
منشأ: كوري عازل للماء . 
متعدد الالوان .
</div>

<br/>

<a
              href={`https://api.whatsapp.com/send?phone=966555755188&text= السلام%20عليكم%20.%20امل%20التواصل%20لطلب%20${company}&source=&data=&app_absent=`}
            target="_blank"
            rel="noopener noreferrer"
          >
                        <button  className='btn-hover butn'>
                        طلب المنتج
                        </button>

          </a>
          <br/>

        </div>
        </div>
            </>
          )
        }

{
          pupValue === 'model1' && (
            <> 
            <div className='pupModelContener'>
    
    
            <div
                  className="experience-bg"
                  style={{ background: experience.colourPrimary }}
                ></div>
                <div className="image-wrapper">
                  <div
                    className="experience-bgtow logo-bg"
                    style={{
                      background: experience.colourSecondary
                        ? experience.colourSecondary
                        : experience.colourPrimary,
                    }}
                  ></div>
                  <img
                    className="company-logo"
                    src={require(`../../images/logos/${company}.png`)}
                    alt={`${company}-logo`}
                    style={
                      experience.logoheight
                        ? {
                            height: `${experience.logoheight}%`,
                          }
                        : { width: `${experience.logowidth}%` }
                    }
                  />
                </div>
    
    
                <div className="experience-card-bottom modeltext">
    
                  <button className='btnClose' onClick={handleClick}>✖️</button>
                <h1>
     التفاصيل
     </h1>
     <br/>
    

                  <div> 
ملعب بنورامي كلاسك احترافي متعدد المستويات
85,000 ريال سعودي
تسليم المصنع غير شامل الضريبة

</div>
                  <br/>
                  <div>       ملعب بنورامي كلاسك احترافي برو متعدد المستويات
                  125,000 ريال سعودي
                  تسليم المصنع غير شامل الضريبة

</div>
    <br/>
                  <h1>متخصصون في</h1>
                  <br/>
    
    
     
     <div>
     تصنيع وتوريد وتركيب وانشاء الحدائق والملاعب بكل اشكالها من 2005 م ومن اوائل من ادخل العشب الصناعي في المملكة العربية السعودية ولله الحمد
توريد وتركيب ملاعب بادل ، نسلمكم ملاعبكم على المفتاح بأفضل المواصفات واسرع وقت ممكن.
توريد وتركيب ملاعب بادل  صناعة اسبانية بانورامية فاخرة  .
توريد  وتركيب ملاعب بادل بمختلف الصناعات  الصينية  .
تصنيع وتوريد وتركيب ملاعب بادل بصناعة وطنية عالية الجودة ومنافس في الأسعار والجودة .
ضمان اعلى ارتداد كرة بادل .
افضل سعر  وافضل جودة .
ضمان ثلاث سنوات شامل الهياكل والزجاج والدهانات والصيانة المجانية طيلة المدة ، وضمان ذهبي لأفضل ارتداد للكورة في الزجاج والارضية.
     </div>
     <br/>
    
     
     <div>
     ملعب بادل تجهيز على المفتاح , بمساحة 200 متر مربع (20م*10م) من الداخل ، ومن الخارج 11*21 بالمواصفات التالية :
- أساسات أرضية حسب احتياج الأرض مع صبة 10-15سم
مع تسليح شبك. 
- تسوير الملعب بهياكل حديد سماكة 3ملم مطلي بدهان أسود عالية الجودة.
- زجاج خلف اللاعبين وجانبهم سماكة 12 ملم بمساحة اجمالية 108 متر مربع. (الخلفية كاملة و4متر لكل جانب )
- عشب بادل كيرلي فاخر حسب مواصفات الاتحاد العالمي 12ملم أزرق توريد وتركيب الرمل السيلكا المناسب للصالات المفتوحة .
- شبك بادل وسط الملعب .
- الكهربة وتمديداتها لثمانية كشافات إنارة بقوة 200واط
مدة التوريد والتركيب  30 يوم ان شاء الله 
ملعب كلاسك تصميم اسباني وتصنيع محلي

     </div>
     <br/>
    
      
     <h1>
     تصاريح البادل 
     </h1>
     <br/>
    
     
    
     <div>
     منصة نافس تابع وزارة الرياضة للتصاريح المشاريع الرياضية. 
    </div>
    
    <br/>
    
    <a
                  href={`https://api.whatsapp.com/send?phone=966555755188&text= السلام%20عليكم%20.%20امل%20التواصل%20لطلب%20${company}&source=&data=&app_absent=`}
                target="_blank"
                rel="noopener noreferrer"
              >
                            <button  className='btn-hover butn'>
                            طلب المنتج
                            </button>
    
              </a>
              <br/>
    
            </div>
            </div>
                </>
          )
        }
        </>
      )
      }
    </div>

    
  )
}

export default ExperienceCard
