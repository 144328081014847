import React from 'react'
import './About.css'
import Typewriter from 'typewriter-effect'
import { Link } from 'react-scroll'
import { Fade } from 'react-reveal'
import Section from '../section/Section'
// import Skills from '../skills/Skills'

const About = () => {
  return (
    <Section title="من نحن">
      <div className="about-content">
        <Fade duration={1000}>
          <div className="about-text">
            {/* <h2>Who am I?</h2> */}
            {/* <p>
              I'm Matt{' '}
              <span role="img" aria-label="lightning">
                😄
              </span>{' '}
            </p> */}
            <p>
            <span role="img" aria-label="lightning">
                ⚡
              </span>{' '}
            تمثل المملكة العربية السعودية و دول المنطقة أحد أهم مناطق العالم في مجال حركة البناء و الأعمار ، وأعمال المقاولات نتيجة تزايد الطلب والنمو السريع وفي ظل النهضة العمرانية الكبيرة التي تشهدها المملكة ودول المنطقة التي أكسبت قطاع المقاولات في المملكة خبرة واسعة في المجال العمراني ، سواء في القطاع العام أو الخاص و أتاحت الحركة العمرانية الواسعة التنافس الكبير في أعمال المقاولات والبناء والأعمار لتلبية احتياجات العملاء ومتطلبات النمو الاقتصادي للمملكة .
            </p>
            <p>
              <span role="img" aria-label="lightning">
                ⚡
              </span>{' '}
              تأسست مجموعة الـمـشـاريـع الـذكـيـة  عام ١٤٢7هـ       للمقاولات و انضمت إلى سوق المنافسة لتفتح آفاق جديدة في مجال المقاولات باحترافية وأمانة.وخلال هذه الفترة   قامت مجموعة الـمـشـاريـع الـذكـيـة  للمقاولات بتنفيذ العديد من المشاريع  للقطاعين الحكومي والخاص .
فعملت مجموعة الـمـشـاريـع الـذكـيـة  على تحقيق تطلعات عملائها وتنويع أعمالها  في المجالات الآتية : 
            </p>
            <div className='liiii'></div>
            <Section title="خدماتنا"> </Section>
            <h2 className='hhh'>أعمال إنشاء المباني</h2>
            <div className="typewriter">
              <p className="typewriter-start">
                <span role="img" aria-label="lightning">
                  ⚡
                </span>{' '}
                تقوم مجموعة الـمـشـاريـع الـذكـيـة بإنشاء المباني السكنية و التجارية والقصور والأبراج من بناء عظم أعمال الإنشاءات وتشطيب وديكورات وأعمال الترميم للمباني والتجمعات السكنية والمساجد وأعمال الطرق والحفر والصرف الصحي والهناجر والمستودعات
              </p>
            </div>
            <h2 className='hhh'>أعمال الإنشاءات المعدنية</h2>
            <div className="typewriter">
              <p className="typewriter-start">
                <span role="img" aria-label="lightning">
                  ⚡
                </span>{' '}
                لدى مجموعة الـمـشـاريـع الـذكـيـة فريق متخصص في إنشاء أعمال المباني المعدنية والمستودعات والحظائر والورش مع تركيب المعدات المتكامل ومراوح التهوية والتبريد والعوازل وشبكات الإطفاء والإنذار للحريق المعتمدة               </p>
            </div>
            <h2 className='hhh'>أعمال  الاتصالات السلكية واللاسلكية</h2>
            <div className="typewriter">
              <p className="typewriter-start">
                <span role="img" aria-label="lightning">
                  ⚡
                </span>{' '}
                تقوم مجموعة الـمـشـاريـع الـذكـيـة بتنفيذ جميع أعمال البنية التحتية للاتصالات السلكية واللاسلكية   من تركيب أبراج وتوصيل ألياف بصرية وغيرها من شبكات الاتصالات السلكية واللاسلكية ولا تزال تنفذ العديد من المشاريع الحيوية  في المملكة العربية السعودية .
ومتخصصون في مجال الاتصالات الوطنية والدولية ولدينا الخبرة في تشغيل وتركيب شبكات الاتصالات السلكية واللاسلكية من خلال طواقم العمل الهندسية و الفنية والكوادر السعودية الوطنية في إدارة جميع أعمالها في المملكة العربية السعودية              </p>
            </div>

            <h2 className='hhh'>أعمال المياه والصرف الصحي </h2>
            <div className="typewriter">
              <p className="typewriter-start">
                <span role="img" aria-label="lightning">
                  ⚡
                </span>{' '}
                تنفيذ أعمال البنية التحتية لشبكات المياه والصرف الصحي وبناء الخزانات الخرسانية والحديدية وتركيب محطات التحلية              </p>
            </div>
            <h2 className='hhh'>أعمال الكهرباء </h2>
            <div className="typewriter">
              <p className="typewriter-start">
                <span role="img" aria-label="lightning">
                  ⚡
                </span>{' '}
                تنفيذ أعمال البنية التحتية للكهرباء من توصيل كيابل وتركيب محولات وتركيب أبراج نقل الطاقة              </p>
            </div>
            <h2 className='hhh'>أعمال التدفئة والتبريد </h2>
            <div className="typewriter">
              <p className="typewriter-start">
                <span role="img" aria-label="lightning">
                  ⚡
                </span>{' '}
تقوم مجموعة الـمـشـاريـع الـذكـيـة بعمل الدراسات اللازمة لتبريد وتدفئة الهواء وحساب الأحمال وتوزيع التكييف ومجاري الهواء وتوريد الأجهزة وتركيبها وتشغيلها بالكامل من قبل مهندسي المجموعة حسب نشاط وظروف المشروع المحيطة .
                   </p>
            </div>
            <h2 className='hhh'>اعمال الديكورات </h2>
            <div className="typewriter">
              <p className="typewriter-start">
                <span role="img" aria-label="lightning">
                  ⚡
                </span>{' '}
                وتشمل الأعمال الجبسية  والأعمال الخشبية والأعمال الحديدية وأعمال الحجر  وأعمال الدهان وأعمال السيراميك والرخام  .
   وتقوم مجموعة الـمـشـاريـع الـذكـيـة بتنفيذ هذه الأعمال طاقم متكامل من ذوي الخبرة والكفاءة العالية من مهندسين ومشرفين وفنيين وعمالة مدربة من نجارين وحدادين ومليسين ومبلطين ودهانين وكهربائيين وسباكين  وهم مجموعة من أفضل العاملين وأمهرهم في مجال العمارة والبناء والمقاولات بشكل عام.                            </p>
            </div>
            <h2 className='hhh'>اعمال انشاء وتشجير وتنسيق الحدائق والملاعب الحديثة </h2>
            <div className="typewriter">
              <p className="typewriter-start">
                <span role="img" aria-label="lightning">
                  ⚡
                </span>{' '}
                تعتبر مجموعة الـمـشـاريـع الـذكـيـة  الرائدة بمجال توريد وتركيب العشب الصناعي بأنواعه   للحدائق  والملاعب والمدارس  والقصور والاستراحات والمنتزهات والميادين العامة  وبإعتمادات الفيفا ورعاية الشباب  ونقوم بتنفيذ مشاريع وزارة التربية والتعليم  والمشاريع الحكومية والخاصة وتنفيذها باحترافية عاليه على امهر الأيدي العاملة والمجموعة  يسعدها تقديم خدماتها لكم بجميع أنحاء المملكة .  
              </p>
            </div>
            <h2 className='hhh'>ملاعب البادل  ( بادل تنس ) </h2>
            <div className="typewriter">
              <p className="typewriter-start">
                <span role="img" aria-label="lightning">
                  ⚡
                </span>{' '}
                متخصصون في تصنيع وتوريد وتركيب ملاعب بادل باعلى المواصفات العالمية
              </p>
            </div>
            <h2 className='hhh'>اعمال القبب والمنازل الريفية</h2>
            <div className="typewriter">
              <p className="typewriter-start">
                <span role="img" aria-label="lightning">
                  ⚡
                </span>{' '}
                توريد وتركيب القبب الزراعية والريفية
                          </p>
            </div>
            <h2 className='hhh'>
            <div className="linktexttt">
          <a
            href="https://drive.google.com/file/d/1pk6B3nm0hZ_1kmcZRy2JAGXG30s3OYSr/view?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
               اعمالنا  - ملف مجموعة المشاريع الذكية  > 
          </a>
          </div>
              
              </h2>
            <br/>
            <div className='liiii'></div>
            <div className="location-wrapper">

              <svg
                className="octicon octicon-location"
                viewBox="0 0 16 16"
                version="1.1"
                width="16"
                height="16"
                aria-hidden="true"
              >
                <path
                  fill="white"
                  fillRule="evenodd"
                  d="M11.536 3.464a5 5 0 010 7.072L8 14.07l-3.536-3.535a5 5 0 117.072-7.072v.001zm1.06 8.132a6.5 6.5 0 10-9.192 0l3.535 3.536a1.5 1.5 0 002.122 0l3.535-3.536zM8 9a2 2 0 100-4 2 2 0 000 4z"
                ></path>
              </svg>
              &nbsp;&nbsp;
              <p> المملكة العربية السعودية الرياض - سبت العلاية </p>
            </div>
          </div>
        </Fade>
        {/* <Skills /> */}
      </div>
    </Section>
  )
}

export default About
